<template>
<div :key="borderKey" ref="borderBox">
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex h10">
        <!-- <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span> -->
        <div class="d-flex assetsPieTitle">
          <span class="fs-xl text_title mx-2 commonTitle">织物资产分析</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="d-flex jc-center h90">
        <CenterLeft1Chart :data="result" />
      </div>
      <!-- 4个主要的数据 -->
      <!-- <div class="bottom-data">
        <div class="item-box mt-2" v-for="(item, index) in numberData" :key="index">
          <div class="d-flex">
            <span class="coin">￥</span>
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p class="text" style="text-align: center;">
            {{ item.text }}
            <span class="colorYellow">(件)</span>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>
import CenterLeft1Chart from '@/components/echart/centerLeft/centerLeft1Chart'
import * as api from '@/api/index'
export default {
  data() {
    return {
      borderKey:'1',
      numberData: [
        {
          number: {
            number: [15],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '待处理'
        },
        {
          number: {
            number: [1144],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '已处理'
        },
        {
          number: {
            number: [361],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '我的流程'
        },
        {
          number: {
            number: [157],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '待签收'
        }
      ],
      params: {
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        customerId: ''
      },
      result: [],
      intervalId: null // 用于存储定时器的 ID
    }
  },
  components: {
    CenterLeft1Chart
  },
  mounted() {
    // this.changeTiming()
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId
    // 首次加载时立即获取数据
    this.getData()
    this.pieOutlineFunc()

    // 设置定时器，每隔 30 分钟获取一次数据
    this.intervalId = setInterval(() => {
      this.getData();
      this.pieOutlineFunc();
    }, 30 * 60 * 1000); // 30 分钟

  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    pieOutlineFunc () {
      var _this=this
      window.addEventListener('resize', function(e){
        _this.$nextTick(()=>{
          // console.log('kkkkkkkkkkkkkkkkkkk')
          _this.borderKey++

          setTimeout(() => { this.$refs.borderBox.initWH() }, 300)

        })

      })
    },

    //织物分类统计
    async getData() {
      const res = await api.getBigSkuCategory(this.params)
      // console.log(res, '-----------666666')
      this.result = res.Result
    },
    changeTiming() {
      setInterval(() => {
        this.changeNumber()
      }, 3000)
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index
        item.number = { ...item.number }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 500px;
$box-height: 350px;

#centerLeft1 {
  //padding: 16px 0px;
  // height: $box-height;
  //width: 100%;
  border-radius: 10px;
  height: 100%;
  .bg-color-black {
    // height: $box-height - 30px;
    border-radius: 10px;
    height: 100%;
  }
  .h10{
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 15px;
    position: relative;
    top: 1rem;
  }
  .h90{
    height: 85%;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    .item-box {
      &>div {
        padding-right: 5px;
      }

      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;

      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }

      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }

      .colorYellow {
        color: yellowgreen;
      }

      p {
        text-align: center;
      }
    }
  }
}
</style>
