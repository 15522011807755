<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex title-box">
        <!-- <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span> -->
        <div class="d-flex">
          <span class="fs-xl text_title mx-2 commonTitle">近一年洗涤量趋势</span>
        </div>
      </div>
      <div class="body-box">
        <BottomCenterChart :data="result" v-if="show" />
      </div>
    </div>
  </div>
</template>

<script>
// import BottomLeftChart from '@/components/echart/bottom/bottomLeftChart'
import BottomCenterChart from '@/components/echart/bottom/bottomCenterChart'
import * as api from '@/api/index'

export default {
  components: {
    // BottomLeftChart
    BottomCenterChart
  },
  data() {
    return {
      params: {
        // pageNum: 1,
        // pageSize: 10,
        // startTime: '',
        // endTime: '',
        customerId: ''
      },
      result: [],
      show: false,
      intervalId: null // 用于存储定时器的 ID
    }
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId

    this.getData()

    // 设置定时器，每隔 30 分钟获取一次数据
    this.intervalId = setInterval(() => {
      this.getData();
    }, 30 * 60 * 1000); // 30 分钟
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    // 填充缺失的月份
    fillMissingMonths(data) {
      const allMonths = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0')); // 生成所有12个月份
      const existingMonths = data.map(item => item.month); // 提取已有的月份
      
      // 找出缺失的月份
      const missingMonths = allMonths.filter(month => !existingMonths.includes(month));
      
      // 填充缺失的月份
      missingMonths.forEach(month => {
        data.push({
          month,
          num: 0,
          remark: null,
          type:[]
        });
      });
      
      // 按照月份的数值大小排序
      data.sort((a, b) => parseInt(a.month) - parseInt(b.month));

      return data;
    },

    async getData() {
      const res = await api.getBigSkuWashMonth(this.params)
      // console.log('近一年洗涤趋势-----------',res, this.params)
      if (res.ResultCode != 0) return
      this.$nextTick(async () => {
        console.warn('近一年洗涤趋势-----------',res.Result)
        this.result = res.Result || []
        this.result = this.fillMissingMonths(this.result)
        // console.error('this.result',  this.result)

       await Promise.all(this.result.map(async (item,index) => {
          let day = await this.getCurrentDate(item.month)
          // console.warn('day---------->', day)
          await this.getBigSkuWashCategoryTime(day).then(res => {
            // console.log(res, '调用接口----------------  ')
            // item.type = res
            this.result[index].type = res
            // return item
          })
        }))

        // console.warn('this',this.result)
        setTimeout(() => {
          this.show = true
        }, 1000);
      })
    },
    getCurrentDate(dateString) {
      var month = dateString.padStart(2, '0'); // 确保月份是两位数
      var year;
      let currMonth = new Date().getMonth()+1;
      if(currMonth >= parseInt(month) ){
        year = new Date().getFullYear(); // 获取当前年份
      }else{
        year = new Date().getFullYear() - 1 // 去年
      }
      
      var day;

      // 2月份特殊处理
      if (month === '02') {
        day = this.isLeapYear(year) ? '29' : '28';
      } else {
        // 其他月份根据是否为闰年来确定天数
        day = [31, this.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][parseInt(month) - 1];
      }

      return [`${year}-${month}-01`, `${year}-${month}-${day}`];
    },
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    },
    async getBigSkuWashCategoryTime(item) {
      const params = {
        customerId: this.$route.query.customerId,
        startTime: item[0],
        endTime: item[1]
      }
      const res = await api.getBigSkuWashCategoryTime(params)
      if (res.ResultCode != 0) return
      return res.Result
    }
  }
}
</script>

<style lang="scss" scoped>
//$box-height: 320px;
//$box-width: 100%;

#bottomLeft {
  //padding: 20px 16px;
  // height: $box-height;
  width: 100%;
  height: 100%;
  border-radius: 5px;

  .bg-color-black {
    height: 100%;
    border-radius: 10px;
    .title-box{
      height: 15%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .body-box{
      height: 85%;
    }
  }

  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;

    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
