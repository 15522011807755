<template>
    <div id="rightCenter">
        <div class="bg-color-black">
            <div class="d-flex title-box">
                <!-- <span>
                    <icon name="chart-area" class="text-icon"></icon>
                </span> -->
                <div class="d-flex">
                    <span class="fs-xl text_title mx-2 commonTitle">织物洗涤次数分析</span>
                    <div class="decoration2">
                        <dv-decoration-2 :reverse="true" style="width:5px;height:6rem;" />
                    </div>
                </div>
            </div>
            <div class="body-box">
                <rightCenterChart :data="result" />
            </div>
        </div>
    </div>
</template>

<script>
import rightCenterChart from "@/components/echart/rightCenter";
import * as api from '@/api/index'

export default {
    components: {
        rightCenterChart
    },
    data() {
        return {
            params: {
                // pageNum: 1,
                // pageSize: 10,
                // startTime: '',
                // endTime: '',
                customerId: ''
            },
            result: [],
            intervalId: null // 用于存储定时器的 ID
        }
    },
    mounted() {
        const info = JSON.parse(localStorage.getItem('userInfo'))
        this.params.customerId = this.$route.query.customerId

        this.getData()

        // 设置定时器，每隔 30 分钟获取一次数据
        this.intervalId = setInterval(() => {
            this.getData();
        }, 30 * 60 * 1000); // 30 分钟
    },
    
    beforeDestroy() {
        // 组件销毁前清除定时器
        if (this.intervalId) {
        clearInterval(this.intervalId);
        }
    },
    methods: {
        async getData() {
            // const res = await api.getBigSkuWashCategoryTime(this.params)
            const res = await api.getBigSkuWashCategory(this.params)
            // console.log(res.Result, '织物洗涤次数分析-----------')
            if (res.ResultCode != 0) return
            this.result = res.Result || []
        },
    }
};
</script>

<style lang="scss" class>
//$box-height: 315px;
//$box-width: 100%;

#rightCenter {
    //padding: 14px 16px;
    // height: $box-height;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    .bg-color-black {
        height: 100%;
        border-radius: 10px;
        .title-box{
            height: 18%;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        .body-box{
            height: 82%;
        }
    }

    //下滑线动态
    .decoration2 {
        position: absolute;
        right: 0.125rem;
    }

    .chart-box {
        margin-top: 16px;
        width: 170px;
        height: 170px;

        .active-ring-name {
            padding-top: 10px;
        }
    }
}
</style>